import { render, staticRenderFns } from "./post-list.html?vue&type=template&id=f51bd8ec&scoped=true&"
import script from "./post-list.js?vue&type=script&lang=js&"
export * from "./post-list.js?vue&type=script&lang=js&"
import style0 from "./post-list.scss?vue&type=style&index=0&id=f51bd8ec&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f51bd8ec",
  null
  
)

export default component.exports