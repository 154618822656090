import { render, staticRenderFns } from "./boats.html?vue&type=template&id=c29c9ec0&scoped=true&"
import script from "./boats.js?vue&type=script&lang=js&"
export * from "./boats.js?vue&type=script&lang=js&"
import style0 from "./boats.scss?vue&type=style&index=0&id=c29c9ec0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c29c9ec0",
  null
  
)

export default component.exports