import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faSearch,
  faLongArrowAltRight,
  faArrowLeft,
  faTimes,
  faSlidersH,
  faMedal,
  faCarSide,
  faFileInvoice,
  faWarehouse,
  faCogs,
  faNewspaper,
  faHome,
  faUserAlt,
  faPlus,
  faMapMarkerAlt,
  faUserTie,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faBolt,
  faStar,
  faStarHalfAlt,
  faCar,
  faCarCrash,
  faPlayCircle,
  faTools,
  faToolbox,
  faExclamationTriangle,
  faPlug,
  faWind,
  faCog,
  faFan,
  faCarBattery,
  faPause,
  faWrench,
  faLifeRing,
  faCircleNotch,
  faUserCheck,
  faThumbtack,
  faDrumSteelpan,
  faShieldAlt,
  faBullseye,
  faMapMarkedAlt,
  faKey,
  faTachometerAlt,
  faPowerOff,
  faHandshake,
  faChargingStation,
  faDiagnoses,
  faHandSparkles,
  faPhone,
  faChevronRight,
  faChevronLeft,
  faCheckSquare,
  faMinusSquare,
  faGripHorizontal,
  faGripLines,
  faShareAlt,
  faSort,
  faDoorOpen,
  faSuitcase,
  faClock,
  faChevronCircleDown,
  faChevronCircleUp,
  faEnvelope,
  faTrash,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

import {
  faCalendarAlt,
  faStar as faStarRegular,
  faCheckSquare as faCheckSquareRegular,
  faSquare,
} from "@fortawesome/free-regular-svg-icons";

import {
  faLinkedin,
  faInstagramSquare,
  faFacebookSquare,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

library.add(faSearch);
library.add(faLongArrowAltRight);
library.add(faArrowLeft);
library.add(faTimes);
library.add(faSlidersH);
library.add(faMedal);
library.add(faCarSide);
library.add(faFileInvoice);
library.add(faWarehouse);
library.add(faCogs);
library.add(faNewspaper);
library.add(faHome);
library.add(faUserAlt);
library.add(faPlus);
library.add(faMapMarkerAlt);
library.add(faCalendarAlt);
library.add(faUserTie);
library.add(faArrowAltCircleLeft);
library.add(faArrowAltCircleRight);
library.add(faBolt);
library.add(faStar);
library.add(faStarHalfAlt);
library.add(faStarRegular);
library.add(faCar);
library.add(faCarCrash);
library.add(faPlayCircle);
library.add(faTools);
library.add(faToolbox);
library.add(faExclamationTriangle);
library.add(faPlug);
library.add(faWind);
library.add(faCog);
library.add(faFan);
library.add(faCarBattery);
library.add(faPause);
library.add(faWrench);
library.add(faLifeRing);
library.add(faCircleNotch);
library.add(faUserCheck);
library.add(faThumbtack);
library.add(faDrumSteelpan);
library.add(faShieldAlt);
library.add(faBullseye);
library.add(faMapMarkedAlt);
library.add(faKey);
library.add(faTachometerAlt);
library.add(faPowerOff);
library.add(faHandshake);
library.add(faChargingStation);
library.add(faDiagnoses);
library.add(faHandSparkles);
library.add(faCheckSquare);
library.add(faPhone);
library.add(faFacebookSquare);
library.add(faInstagramSquare);
library.add(faLinkedin);
library.add(faWhatsapp);
library.add(faChevronRight);
library.add(faChevronLeft);
library.add(faCheckSquare);
library.add(faCheckSquareRegular);
library.add(faSquare);
library.add(faMinusSquare);
library.add(faGripHorizontal);
library.add(faGripLines);
library.add(faShareAlt);
library.add(faSort);
library.add(faDoorOpen);
library.add(faSuitcase);
library.add(faClock);
library.add(faChevronCircleDown);
library.add(faChevronCircleUp);
library.add(faUserCheck);
library.add(faEnvelope);
library.add(faTrash);
library.add(faCheckSquare);
library.add(faInfoCircle);
