import { render, staticRenderFns } from "./fixed-home-buttons.html?vue&type=template&id=7bcff617&scoped=true&"
import script from "./fixed-home-buttons.js?vue&type=script&lang=js&"
export * from "./fixed-home-buttons.js?vue&type=script&lang=js&"
import style0 from "./fixed-home-buttons.scss?vue&type=style&index=0&id=7bcff617&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bcff617",
  null
  
)

export default component.exports