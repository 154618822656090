import { render, staticRenderFns } from "./text-area.html?vue&type=template&id=229091b8&scoped=true&"
import script from "./text-area.js?vue&type=script&lang=js&"
export * from "./text-area.js?vue&type=script&lang=js&"
import style0 from "./text-area.scss?vue&type=style&index=0&id=229091b8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "229091b8",
  null
  
)

export default component.exports