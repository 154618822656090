import { render, staticRenderFns } from "./spare-parts-details.html?vue&type=template&id=5eb4087e&scoped=true&"
import script from "./spare-parts-details.js?vue&type=script&lang=js&"
export * from "./spare-parts-details.js?vue&type=script&lang=js&"
import style0 from "./spare-parts-details.scss?vue&type=style&index=0&id=5eb4087e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5eb4087e",
  null
  
)

export default component.exports