import { render, staticRenderFns } from "./coming-soon.html?vue&type=template&id=75314c74&scoped=true&"
import script from "./coming-soon.js?vue&type=script&lang=js&"
export * from "./coming-soon.js?vue&type=script&lang=js&"
import style0 from "./coming-soon.scss?vue&type=style&index=0&id=75314c74&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75314c74",
  null
  
)

export default component.exports