import { render, staticRenderFns } from "./sub-category-list.html?vue&type=template&id=b695b002&scoped=true&"
import script from "./sub-category-list.js?vue&type=script&lang=js&"
export * from "./sub-category-list.js?vue&type=script&lang=js&"
import style0 from "./sub-category-list.scss?vue&type=style&index=0&id=b695b002&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b695b002",
  null
  
)

export default component.exports